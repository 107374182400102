import Button from "react-bootstrap/Button";
import React, {MouseEvent} from "react";
import { Link } from "react-router-dom";
import Order from "../order/Order";
import { SortedBy } from "../../sort/SortedBy";
import SortIcon from "../sortIcon/SortIcon";

import "./DetailedOrderTable.css"
import FilterUI from "../filterUI/FilterUI";

function detailPath(path: string, projectNumber: string, materialReq: string, purchaseNumber: number) {
  if (purchaseNumber === 0) {
    return path + projectNumber + "-" + materialReq;
  } else {
    return path + projectNumber + "-" + materialReq + "-" + purchaseNumber;
  }
}

type CloseMRCallback = (projectNumber: string, materialRequest: string, e: MouseEvent) => void;
type SortOrdersCallback = (id: number, sortBy: string) => any;
type FilterOrdersCallback = (filterText: string, filterOnField: string[], id: number) => any;
type Props = {
  className?: string,
  orders: Order[],
  materialTable: boolean,
  filter?: FilterOrdersCallback,
  closeMaterialRequestHandler?: CloseMRCallback,
  path: string,
  id: number,
  sortedBy: SortedBy,
  sort: SortOrdersCallback,
}

function DetailedOrderTable(props: Props) {
  const orders = props.orders;
  const listItems = orders.map((order, index) => (
    <tr className="TableRow" key={index}>
      <td>
        <Link
          to={{
            pathname: detailPath(
              props.path,
              order.projectNumber,
              order.materialReq,
              order.purchaseNumber
            ),
            state: {projectNumber: order.projectNumber, materialReq: order.materialReq, purchaseNumber: order.purchaseNumber}
          }}
        >
          {order.projectNumber + "-" + order.materialReq}
        </Link>
      </td>
      {props.materialTable ? null : (
        <td>
          {order.purchaseNumber === 0
            ? " "
            : order.projectNumber + "-" + order.purchaseNumber}
        </td>
      )}
      {props.materialTable ? null : <td>{order.vendor}</td>}
      <td>{order.orderer}</td>
      <td>{order.description}</td>
      <td>
        {new Date(order.submittedDate).toLocaleDateString("en-US", {
          timeZone: "UTC",
        })}
      </td>
      <td>
        {new Date(order.neededDate).toLocaleDateString("en-US", {
          timeZone: "UTC",
        })}
      </td>
      {props.materialTable ? null : (
        <td>
          {new Date(order.orderedDate).toLocaleDateString("en-US", {
            timeZone: "UTC",
          })}
        </td>
      )}
      <td>{order.status}</td>
      {props.materialTable ? null : <td>{order.issue}</td>}
      {props.materialTable === true ? (
        <td>
          <Link
            to={{
              pathname: "/new-purchase-order",
              state:{
                projectNumber: order.projectNumber,
                materialRequest: order.materialReq,
              }
            }}
          >
            Create Purchase Order
          </Link>
          <Button
            variant="secondary"
            onClick={(e) => {
              if(props.closeMaterialRequestHandler){
                props.closeMaterialRequestHandler(
                  order.projectNumber,
                  order.materialReq,
                  e
                )
              }
            }
            }
          >
            Done Ordering
          </Button>{" "}
        </td>
      ) : null}
      {props.materialTable === true ? (
        <td>
          {order.beingOrdered
            ? "Order in progress by " + order.beingOrdered
            : ""}
        </td>
      ) : null}
    </tr>
  ));
  const tableHead = (
    <tr>
      <th>Material Req # {(typeof props.filter === "undefined") ? null : <FilterUI filter={props.filter} id={props.id} filterOnField={["projectNumber", "materialReq"]} placeholderName="Job #"/>}</th>
      {props.materialTable ? null : <th>Purchase Order # {(typeof props.filter === "undefined") ? null : <FilterUI filter={props.filter} id={props.id} filterOnField={["projectNumber", "purchaseNumber"]} placeholderName="Job #"/>}</th> }
      {props.materialTable ? null : <th>Vendor {(typeof props.filter === "undefined") ? null : <FilterUI filter={props.filter} id={props.id} filterOnField={["vendor"]} placeholderName="Vendor"/>}</th>}
      <th>
        Ordered By 
        <span onClick={() => props.sort(props.id, "orderer")}>
         <SortIcon sortOrder={props.sortedBy.orderer} />
        </span>
        {(typeof props.filter === "undefined") ? null : <FilterUI filter={props.filter} id={props.id} filterOnField={["orderer"]} placeholderName="Order By"/>}
        
      </th>
      <th>Description</th>
      <th>
        Date Submitted 
        <span  onClick={() => props.sort(props.id, "submittedDate")}>
           <SortIcon sortOrder={props.sortedBy.submittedDate} />
        </span>
      </th>
      <th>
        Date Needed 
        <span  onClick={() => props.sort(props.id, "neededDate")}>
          <SortIcon sortOrder={props.sortedBy.neededDate} />
        </span>
      </th>
      {props.materialTable ? null : (
        <th>
           Date Ordered 
          <span  onClick={() => props.sort(props.id, "orderedDate")}>
           <SortIcon sortOrder={props.sortedBy.orderedDate} />
          </span>
        </th>
      )}
      <th>Status</th>
      {props.materialTable ? null : <th>Issue?</th>}
    </tr>
  );
  return (
    <table className="DetailedOrderTable">
      <thead className="TableHeader">{tableHead}</thead>
      <tbody>{listItems}</tbody>
    </table>
  );
}

export default DetailedOrderTable;
