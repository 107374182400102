import firebase from "../firebase/Firebase";
import Order from "../order/Order";
class Database {
  constructor() {
    this.db = firebase.firestore();
    this.storage = firebase.storage();
  }

  getProjectNames = async () => {
    return await this.db.collection("projectNames")
    .get()
    .then(function(querySnapShot){
      let projectNames = [];
      querySnapShot.forEach(function(doc){
        projectNames.push(doc.data());
      });
      return projectNames[0].projectNames;
    })
  }


  createProject = (projectNumber, requestValue, purchaseValue, address) => {
    this.db.collection("projects").add({
      project: projectNumber,
      requestNumber: requestValue,
      purchaseNumber: purchaseValue,
      address: Object.assign({}, address),
    });
  };

  updateProject = (projectNumber, requestValue, purchaseValue, address) => {
    this.db
      .collection("projects")
      .where("project", "==", projectNumber)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (document) {
          if (requestValue != null) {
            document.ref.update({
              requestNumber: requestValue,
              address: Object.assign({}, address),
            });
          } else {
            document.ref.update({ purchaseNumber: purchaseValue });
          }
        });
      });
  };

  getProject = async (projectNumber) => {
      return await this.db
      .collection("projects")
      .where("project", "==", projectNumber)
      .get()
      .then(function (querySnapshot) {
        let projects = [];
        querySnapshot.forEach(function (doc) {
          projects.push(doc.data());
        });
        return projects[0];
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };

  getOrderItems = async () => {
    return await this.db
    .collection("orderItems")
    .get()
    .then(function(querySnapShot){
      let orderLists = {};
      querySnapShot.forEach(function(doc){
        orderLists[doc.id] = doc.data();
      });
      return orderLists;
    })
  }

  createDraftMaterialRequest = (
    email,
    projectNum,
    needed,
    orderDescription,
    order,
    shippingAddress,
    shippingInstructions,
    materialRequestURL,
    orderItems,
    id
    ) => {
      let newOrder = new Order();
      newOrder.orderer = email;
      newOrder.materialReq = -1;
      newOrder.projectNumber = projectNum;
      newOrder.submittedDate = null;
      newOrder.neededDate = needed;
      newOrder.status = "Draft";
      newOrder.orderDetails = order;
      newOrder.description = orderDescription;
      newOrder.shippingAddress = Object.assign({}, shippingAddress);
      newOrder.shippingInstructions = shippingInstructions;
      newOrder.materialRequestURL = materialRequestURL;
      newOrder.beingOrdered = null;
      newOrder.orderItems = orderItems;
      if(id === null){
        this.db.collection("materialRequests").add({...newOrder});
      }
      else{
        let draft = this.db.collection("materialRequests").document(id);
        draft.update({...newOrder})
      }
  }

  getDraftMaterialRequest = async (id) => {
    return await this.db.collection("materialRequests")
    .doc(id)
    .get()
    .then((doc) => {
      return doc.data();
    });
  }

  createMaterialRequest = async (
    email,
    initials,
    projectNum,
    needed,
    orderDescription,
    order,
    shippingAddress,
    shippingInstructions,
    materialRequestURL,
    orderItems,
    draftID
  ) => {
    projectNum = projectNum.toUpperCase();
    let project = await this.getProject(projectNum);
    let requestNumber = 0;
    if (project == null) {
      this.createProject(projectNum, 1, 0, shippingAddress);
    } 
    else {
      requestNumber = project.requestNumber;
      this.updateProject(
        projectNum,
        requestNumber + 1,
        null,
        shippingAddress
      );
    }
    let newOrder = new Order();
    newOrder.orderer = email;
    newOrder.materialReq = initials + (requestNumber + 1);
    newOrder.projectNumber = projectNum;
    newOrder.submittedDate = new Date().toLocaleString();
    newOrder.neededDate = needed;
    newOrder.status = "Submitted";
    newOrder.orderDetails = order;
    newOrder.description = orderDescription;
    newOrder.shippingAddress = Object.assign({}, shippingAddress);
    newOrder.shippingInstructions = shippingInstructions;
    newOrder.materialRequestURL = materialRequestURL;
    newOrder.beingOrdered = null;
    newOrder.orderItems = orderItems;
    this.db.collection("materialRequests").add({ ...newOrder });
    if(draftID != null){
      this.db.collection("materialRequests").doc(draftID).delete().then();
    }
    return newOrder;
  };

  getOpenMaterialRequestsForUser = (email, callback) => {
    this.db
      .collection("materialRequests")
      .where("orderer", "==", email)
      .where("orderNotCompleted", "==", true)
      .where("ordered", "==", false)
      .orderBy("projectNumber")
      .orderBy("submittedDate")
      .onSnapshot((querySnapshot) => {
        let orders = [];
        querySnapshot.forEach(function (doc) {
          let order = doc.data();
          order["id"] = doc.id;
          orders.push(order);
        });
        callback(orders);
      });
  };

  getOpenMaterialRequests = (callback) => {
    this.db
      .collection("materialRequests")
      .where("orderNotCompleted", "==", true)
      .where("ordered", "==", false)
      .where("status", "==", "Submitted")
      .orderBy("submittedDate", "desc")
      .onSnapshot((querySnapshot) => {
        let orders = [];
        querySnapshot.forEach(function (doc) {
          let order = doc.data();
          order.neededDate = new Date(order.neededDate);
          order.submittedDate = new Date(order.submittedDate);
          orders.push(order);
        });
        callback(orders);
      });
  };

  getCompletedMaterialRequests = (callback) => {
    this.db
      .collection("materialRequests")
      .where("orderNotCompleted", "==", true)
      .where("ordered", "==", true)
      .orderBy("submittedDate", "desc")
      .onSnapshot((querySnapshot) => {
        let orders = [];
        querySnapshot.forEach(function (doc) {
          let order = doc.data();
          order.neededDate = new Date(order.neededDate);
          order.submittedDate = new Date(order.submittedDate);
          orders.push(order);
        });
        callback(orders);
      });
  };

  getMaterialRequest = (projectNumber, materialReq, callback) => {
    this.db
      .collection("materialRequests")
      .where("projectNumber", "==", projectNumber)
      .where("materialReq", "==", materialReq)
      .get()
      .then(function (querySnapshot) {
        let orders = [];
        querySnapshot.forEach(function (doc) {
          orders.push(doc.data());
        });
        callback(orders);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
  getMaterialRequestDetail = (
    projectNumber,
    materialReq,
    purchaseNumber,
    callback
  ) => {
    this.db
      .collection("materialRequests")
      .where("projectNumber", "==", projectNumber)
      .where("materialReq", "==", materialReq)
      .where("purchaseNumber", "==", purchaseNumber)
      .get()
      .then(function (querySnapshot) {
        let orders = [];
        querySnapshot.forEach(function (doc) {
          orders.push(doc.data());
        });
        callback(orders);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };

  closeMaterialRequest = (projectNum, materialReq, callback) => {
    this.db
      .collection("materialRequests")
      .where("projectNumber", "==", projectNum)
      .where("materialReq", "==", materialReq)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (document) {
          document.ref.update({ ordered: true });
        });
        callback();
      });
  };

  cancelMaterialRequest = (projectNum, materialReq) => {
    this.db
      .collection("materialRequests")
      .where("projectNumber", "==", projectNum)
      .where("materialReq", "==", materialReq)
      .where("beingOrdered", "==", null)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach((document) => {
          document.ref.update({ ordered: true });
        });
      });
  };

  startPurchaseOrder = (projectNum, materialReq, orderer) => {
    this.db
      .collection("materialRequests")
      .where("projectNumber", "==", projectNum)
      .where("materialReq", "==", materialReq)
      .get()
      .then((querySnapShot) => {
        querySnapShot.forEach((document) => {
          document.ref.update({ beingOrdered: orderer });
        });
      });
  }

  createPurchaseOrder = (projectNum, initials, order) => {
    const project = this.getProject(projectNum); 
    project.then(project => {
      let purchaseNumber = 0;
      purchaseNumber = project.purchaseNumber;
      order.purchaseNumber = initials + (purchaseNumber + 1);
      this.updateProject(projectNum, null, purchaseNumber + 1, null);
      this.db.collection("purchaseOrders").add(order);
    });
  };

  getPurchaseOrdersForUser = (email, callback) => {
    this.db
      .collection("purchaseOrders")
      .where("orderer", "==", email)
      .where("orderNotCompleted", "==", true)
      .orderBy("projectNumber")
      .orderBy("submittedDate")
      .onSnapshot((querySnapshot) => {
        let orders = [];
        querySnapshot.forEach(function (doc) {
           let order = doc.data();
           order.neededDate = new Date(order.neededDate);
           order.orderedDate = new Date(order.orderedDate);
           order.submittedDate = new Date(order.submittedDate);
           orders.push(order);
        });
        callback(orders);
      });
  };

  getCompletePurchaseOrdersForUser = (email, callback) => {
    this.db
      .collection("purchaseOrders")
      .where("orderer", "==", email)
      .where("orderNotCompleted", "==", false)
      .orderBy("projectNumber")
      .orderBy("submittedDate")
      .onSnapshot((querySnapshot) => {
        let orders = [];
        querySnapshot.forEach(function (doc) {
           let order = doc.data();
           order.neededDate = new Date(order.neededDate);
           order.orderedDate = new Date(order.orderedDate);
           order.submittedDate = new Date(order.submittedDate);
           orders.push(order);
        });
        callback(orders);
      });
  };

  getPurchaseOrders = (callback) => {
    this.db
      .collection("purchaseOrders")
      .where("orderNotCompleted", "==", true)
      .orderBy("orderedDate", "desc")
      .onSnapshot((querySnapshot) => {
        let orders = [];
        querySnapshot.forEach(function (doc) {
          let order = doc.data();
          order.neededDate = new Date(order.neededDate);
          order.orderedDate = new Date(order.orderedDate);
          order.submittedDate = new Date(order.submittedDate);
          orders.push(order);
        });
        callback(orders);
      });
  };

  getPurchaseOrdersWithIssues = (callback) => {
    this.db
      .collection("purchaseOrders")
      .where("orderNotCompleted", "==", true)
      .where("orderHasIssue", "==", true)
      .orderBy("issueDate").onSnapshot(querySnapShot => {
        let orders = [];
        querySnapShot.forEach(doc => {
           let order = doc.data();
           order.neededDate = new Date(order.neededDate);
           order.orderedDate = new Date(order.orderedDate);
           order.submittedDate = new Date(order.submittedDate);
           orders.push(order);
        });
        callback(orders);
      })
  }

  getCompletePurchaseOrders = (callback) => {
    this.db
      .collection("purchaseOrders")
      .where("orderNotCompleted", "==", false)
      .orderBy("projectNumber")
      .orderBy("submittedDate")
      .onSnapshot((querySnapshot) => {
        let orders = [];
        querySnapshot.forEach(function (doc) {
           let order = doc.data();
           order.neededDate = new Date(order.neededDate);
           order.orderedDate = new Date(order.orderedDate);
           order.submittedDate = new Date(order.submittedDate);
           orders.push(order);
        });
        callback(orders);
      });
  };

  getOrderDetail = (projectNumber, materialReq, purchaseNumber, callback) => {
    this.db
      .collection("purchaseOrders")
      .where("projectNumber", "==", projectNumber)
      .where("materialReq", "==", materialReq)
      .where("purchaseNumber", "==", purchaseNumber)
      .get()
      .then(function (querySnapshot) {
        let orders = [];
        querySnapshot.forEach(function (doc) {
          orders.push(doc.data());
        });
        callback(orders[0]);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };

  updateOrderStatus = (projectNumber, materialReq, purchaseNumber, status) => {
    let update = { status: status };
    if (status === "Complete") {
      update = {
        status: status,
        orderNotCompleted: false,
        completedDate: new Date().toLocaleDateString(),
      };
    } else if (status === "Canceled") {
      update = {
        status: status,
        orderNotCompleted: false,
      };
    } else if (status === "Received Shop") {
      update = {
        status: status,
        orderNotCompleted: true,
        receivedShop: new Date().toLocaleDateString(),
      };
    } else if (status === "Received Field") {
      update = {
        status: status,
        orderNotCompleted: true,
        receivedField: new Date().toLocaleDateString(),
      };
    }
    this.db
      .collection("purchaseOrders")
      .where("projectNumber", "==", projectNumber)
      .where("materialReq", "==", materialReq)
      .where("purchaseNumber", "==", purchaseNumber)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (document) {
          document.ref.update(update);
        });
      });
  };

  updateOrderIssues = (projectNumber, materialReq, purchaseNumber, issues, orderIssueDetail) => {
    let update = {issue: issues, orderIssueDetail: orderIssueDetail};
    if(issues === ""){
      update["orderHasIssue"] = false;
    }
    else {
      update["orderHasIssue"] = true;
      update["issueDate"] = new Date().toLocaleString();
    }
    this.db
      .collection("purchaseOrders")
      .where("projectNumber", "==", projectNumber)
      .where("materialReq", "==", materialReq)
      .where("purchaseNumber", "==", purchaseNumber)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (document) {
          document.ref.update(update);
        });
      });
  };

  updatePackingSlip = (projectNumber, materialReq, purchaseNumber, URL) => {
    let update = { packingSlipURL: URL };
    this.db
      .collection("purchaseOrders")
      .where("projectNumber", "==", projectNumber)
      .where("materialReq", "==", materialReq)
      .where("purchaseNumber", "==", purchaseNumber)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (document) {
          document.ref.update(update);
        });
      });
  };

  updateMaterialRequestURL = (
    projectNumber,
    materialReq,
    purchaseNumber,
    URLs
  ) => {
    let update = { materialRequestURL: URLs };
    this.db
      .collection("purchaseOrders")
      .where("projectNumber", "==", projectNumber)
      .where("materialReq", "==", materialReq)
      .where("purchaseNumber", "==", purchaseNumber)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (document) {
          document.ref.update(update);
        });
      });
  };

  updateAttachmentURL = (
    projectNumber,
    materialReq,
    purchaseNumber,
    URLs,
    URLAttachmentType
    ) => {
      let update = {};
      if(URLAttachmentType === "MR"){
        update = { materialRequestURL: URLs }; 
      }
      else if(URLAttachmentType === "PO"){
        update = { purchaseOrderURL: URLs };
      }
      else if(URLAttachmentType === "ISSUE"){
        update = { issueURL: URLs}
      }
      else {
        return false;
      }
      this.db
        .collection("purchaseOrders")
        .where("projectNumber", "==", projectNumber)
        .where("materialReq", "==", materialReq)
        .where("purchaseNumber", "==", purchaseNumber)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (document) {
            document.ref.update(update);
          });
        });
  }

  createVendor = (vendorContact, vendorEmail, vendorName, vendorPhone) => {
    this.db.collection("vendors").add({
      vendorContact: vendorContact,
      vendorEmail: vendorEmail,
      vendorName: vendorName,
      vendorPhone: vendorPhone,
    });
  };

  getVendors = (callback) => {
    this.db
      .collection("vendors")
      .get()
      .then(function (querySnapshot) {
        let vendors = [];
        querySnapshot.forEach(function (doc) {
          vendors.push(doc.data());
        });
        callback(vendors);
      });
  };
}

export default Database;
