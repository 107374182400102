import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./NewOrderView.css";
class NewOrderView extends Component {
  render() {
    return (
      <div className="OrderChoice">
        <Link className="DirectOrderButton" to={"direct-order"}>
          New Order
        </Link>
      </div>
    );
  }
}

export default NewOrderView;
