import React from "react";
import { Formik } from "formik";
import Database from "../database/Database";
import firebase from "../firebase/Firebase";
import Address from "../address/Address";
import Form from "react-bootstrap/Form";
import * as yup from "yup";

const schema = yup.object({
  project: yup.string().required("Enter Job Number").matches(/[0-9]{2}[a-zA-Z][0-9]{3}/, "Example format 21Z001"),
  neededDate: yup.date().required(),
  shortDescription: yup.string().required(),
  vendor: yup.string().required(),
  shippingInstructions: yup.string().required(),
});

const NewPurchaseOrderForm = (props) => (
  <Formik
    validationSchema={schema}
    initialValues={{
      orderer: props.state.orderer,
      project: props.state.projectNumber,
      materialReq: props.state.materialReq,
      submittedDate: props.state.submittedDate,
      neededDate: props.state.neededDate,
      status: props.state.status,
      ordered: false,
      vendor: "",
      shortDescription: props.state.description,
      orderDetails: props.state.orderDetails,
      street: props.state.shippingAddress.street,
      city: props.state.shippingAddress.city,
      state: props.state.shippingAddress.state,
      zipCode: props.state.shippingAddress.zipCode,
      shippingInstructions: props.state.shippingInstructions,
      cost: "",
    }}
    onSubmit={(values) => {
      let db = new Database();
      let address = new Address();
      address.street = values.street;
      address.city = values.city;
      address.state = values.state;
      address.zipCode = values.zipCode;
      address.country = "USA";
      firebase.auth().onAuthStateChanged((user) => {
        db.createPurchaseOrder(
          values.project,
          user.displayName.split(" ")[0][0] + user.displayName.split(" ")[1][0],
          {
            orderer: values.orderer,
            orderDetails: values.orderDetails,
            projectNumber: values.project,
            purchaseNumber: 0,
            submittedDate: props.state.submittedDate,
            orderedDate: new Date().toLocaleString(),
            receivedShop: "",
            willCall: "",
            receivedField: "",
            completedDate: "",
            issue: "",
            neededDate: values.neededDate,
            status: "Ordered",
            ordered: true,
            vendor: values.vendor,
            description: values.shortDescription,
            orderNotCompleted: true,
            shippingAddress: Object.assign({}, address),
            shippingInstructions: values.shippingInstructions,
            materialReq: props.state.materialReq,
            cost: values.cost,
            materialRequestURL:
              props.state.urls !== ""
                ? props.state.urls
                : props.state.materialRequestURL,
            
          }
        );
      });
      props.history.goBack();
    }}
  >
    {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
    }) => (
      <Form onSubmit={handleSubmit}>
        <p>Purchase Order: {props.state.projectNumber + "-" + props.state.user.displayName.split(" ")[0][0] + props.state.user.displayName.split(" ")[1][0] + props.state.purchaseOrderNum}</p>
        <Form.Group>
          <div>Submitted Date: {new Date(props.state.submittedDate).toLocaleString('en-US', {timeZone: "PST"})}</div>
          <Form.Label htmlFor="orderer">Ordered By</Form.Label>
          <Form.Control
            id="orderer"
            name="orderer"
            value={values.orderer}
            onChange={handleChange}
            isInvalid={!!errors.project}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="project">Project #</Form.Label>
          <Form.Control
            id="project"
            name="project"
            value={values.project}
            onChange={handleChange}
            isInvalid={!!errors.project}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="neededDate">Date Needed</Form.Label>
          <Form.Control
            id="neededDate"
            name="neededDate"
            type="date"
            value={values.neededDate}
            onChange={(e) => {
              handleChange(e);
              props.updateState("neededDate", e.target.value)
            }}
            isInvalid={!!errors.neededDate}
          />
        </Form.Group>
       
        <Form.Group>
          <Form.Label htmlFor="ShortDescription">Short Description</Form.Label>
          <Form.Control
            as="textarea"
            id="shortDescription"
            name="shortDescription"
            type="text"
            value={values.shortDescription}
            onChange={(e) => {
              handleChange(e);
              props.updateState("description", e.target.value)
            }}
            isInvalid={!!errors.shortDescription}
            rows="1"
            cols="30"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="order">Order Details</Form.Label>
          <Form.Control
            as="textarea"
            id="orderDetails"
            name="orderDetails"
            type="text"
            value={values.orderDetails}
            onChange={(e) => {
              handleChange(e);
              props.updateState("orderDetails", e.target.value)
            }}
            rows="6"
            cols="40"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="vendor">Vendor</Form.Label>
          <Form.Control
            id="vendor"
            name="vendor"
            value={values.vendor}
            onChange={(e) => {
              handleChange(e);
              props.updateState("vendor", e.target.value)
            }}
            isInvalid={!!errors.vendor}
          />
        </Form.Group>
        <h5>Shipping Information</h5>
        <Form.Group>
          <Form.Label htmlFor="street">Street</Form.Label>
          <Form.Control
            as="textarea"
            id="street"
            name="street"
            type="text"
            value={values.street}
            onChange={(e) => {
              handleChange(e);
              props.updateState("street", e.target.value)
            }}
            isInvalid={!!errors.street}
            rows="2"
            cols="40"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="city">City</Form.Label>
          <Form.Control
            id="city"
            name="city"
            type="text"
            value={values.city}
            onChange={(e) => {
              handleChange(e);
              props.updateState("city", e.target.value)
            }}
            isInvalid={!!errors.city}
            rows="1"
            cols="40"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="state">State</Form.Label>
          <Form.Control
            id="state"
            name="state"
            value={values.state}
            onChange={(e) => {
              handleChange(e);
              props.updateState("state", e.target.value)
            }}
            isInvalid={!!errors.state}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="zipCode">ZIP Code</Form.Label>
          <Form.Control
            id="zipCode"
            name="zipCode"
            value={values.zipCode}
            onChange={(e) => {
              handleChange(e);
              props.updateState("zipCode", e.target.value)
            }}
            isInvalid={!!errors.zipCode}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="shippingInstructions">
            Shipping Instructions
          </Form.Label>
          <Form.Control
            as="textarea"
            id="shippingInstructions"
            name="shippingInstructions"
            type="text"
            value={values.shippingInstructions}
            onChange={(e) => {
              handleChange(e);
              props.updateState("shippingInstructions", e.target.value)
            }}
            isInvalid={!!errors.shippingInstructions}
            rows="4"
            cols="40"
          />
        </Form.Group>
      </Form>
    )}
  </Formik>
);

export default NewPurchaseOrderForm;
