import React, {useState} from "react";
import { Button } from "react-bootstrap";
import firebase from "firebase";
import Address from "../../components/address/Address";
import Database from "../../components/database/Database";
import ListItems from '../../components/newOrder/ListItems';

type Props = {
    location: any,
    history: any,
}



function DraftOrder(props: Props) {
    let draftMR = props.location.state.draftMR;
    let orderItems = props.location.state.orderItems;
    let orderURLS = props.location.state.orderURLS;
    let draftID = props.location.state.draftID;
    const [orderItemsState, setOrderItemsState] = useState(orderItems);
    const removeOrderItem = (item: any) => {
        let orderItems = orderItemsState.filter((value: any) => {
            return value.id !== item.id;
          })
          orderItems.push(item);
          setOrderItemsState(orderItems);
    }
    const submitOrder = (orderInformation: any, order: any, orderURLs: any) => {
        firebase.auth().onAuthStateChanged((user) => {
            let db = new Database();
            let address = new Address();
            address.street = orderInformation.street;
            address.city = orderInformation.city;
            address.state = orderInformation.state;
            address.zipCode = orderInformation.zipCode;
            address.country = "USA";
            if (user && user.email && user.displayName) {
              const createdOrder = db.createMaterialRequest(
                user.email,
                user.displayName.split(" ")[0][0] +
                  user.displayName.split(" ")[1][0],
                  orderInformation.project,
                  orderInformation.neededDate,
                  orderInformation.shortDescription,
                  orderInformation.order,
                address,
                orderInformation.shippingInstructions,
                orderURLs,
                order,
                draftID,
              );
              if (orderInformation.attachByEmail) {
                createdOrder.then((order) => {
                  window.location.href = `mailto:edgar@agcinc.com?cc=kgill@agcinc.com&subject=${`PROCUREMENT Attachment For ${order.projectNumber}-${order.materialReq} From ${user.displayName}`}`;
                });
              }
            }
    
            props.history.push("/");
          });
    }
    return (
        <div>
            <div>Project: {draftMR.project} - {draftMR.projectName}</div>
            <div>Needed: {draftMR.neededDate}</div>
            <div>Description: {draftMR.shortDescription}</div>
            <div>Order: {draftMR.order}</div>
            <div>Address: {draftMR.street} {draftMR.city} {draftMR.state} {draftMR.zipCode}</div>
            <div>Shipping Instructions: {draftMR.shippingInstructions}</div>

            <ListItems items={orderItemsState} callBack={removeOrderItem}/>

            <Button onClick={() => submitOrder(draftMR, orderItemsState, orderURLS)}>Submit Order</Button>
        </div>
    )
}





export default DraftOrder;