import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "./OrderTable.css";

type CancelMaterialRequest = (projectNumber: string, materialReqNum: number) => void;

type Props = {
  orders: any,
  path: string,
  materialTable: boolean,
  cancelMaterialRequest?: CancelMaterialRequest,
}


function tableRowClass(status: string) {
  let CSSClass = "";
  if (status === "Submitted") {
    CSSClass = "Submitted";
  } else if (status === "Ordered") {
    CSSClass = "Ordered";
  } else if (status === "Received Shop") {
    CSSClass = "ReceivedShop";
  } else if (status === "Will Call") {
    CSSClass = "WillCall";
  } else if (status === "Complete") {
    CSSClass = "Complete";
  } else if (status === "Canceled") {
    CSSClass = "Canceled";
  } else if (status === "Received Field") {
    CSSClass = "ReceivedField";
  }
  return CSSClass;
}

function detailPath(path: string, projectNumber: string, materialReq: number, purchaseNumber: number, draft: string, id: string) {
  if(draft === "Draft"){
    return "direct-order/" + id;
  }
  else {
    if (purchaseNumber === 0) {
      return path + projectNumber + "-" + materialReq;
    } else {
      return path + projectNumber + "-" + materialReq + "-" + purchaseNumber;
    }
  }
  
}

function OrderTable(props: Props) {
  const orders = props.orders;
  const listItems = orders.map((order: any, index: number) => (
    <tr className={"TableRow " + tableRowClass(order.status)} key={index}>
      <td>
        <Link
          to={{
            pathname: detailPath(
              props.path,
              order.projectNumber,
              order.materialReq,
              order.purchaseNumber,
              order.status,
              order.id
            ),
          }}
        >
          {order.projectNumber + "-" + order.materialReq}
        </Link>
      </td>
      {props.materialTable ? null : 
      <td>{order.projectNumber + "-" + order.purchaseNumber}</td>}
      <td>{order.description}</td>
      <td>
        {new Date(order.neededDate).toLocaleDateString("en-US", {
          timeZone: "UTC",
        })}
      </td>
      <td>{order.status}</td>
      {props.materialTable ? (
        (order.beingOrdered === null) ? (
          <td>
            <Button
              variant="danger"
              onClick={(e) => {
                if(props.cancelMaterialRequest){
                  props.cancelMaterialRequest(
                    order.projectNumber,
                    order.materialReq
                  );
                }
              }}
            >
              Cancel
            </Button>
          </td>
        ) : (
          <td>In progress {order.beingOrdered}</td>
        )
      ) : (
        <td>{order.issue}</td>
      )}
    </tr>
  ));
  const tableHead = (
    <thead className="TableHeader">
      <tr>
        <th>Material Req #</th>
        {props.materialTable ? null :<th>Purchase Order #</th>}
        <th>Description</th>
        <th>Date Needed</th>
        <th>Status</th>
        {props.materialTable ? null : <th>Issue?</th>}
      </tr>
    </thead>
  );
  return (
    <table className="OrderTable">
      {tableHead}
      <tbody>{listItems}</tbody>
    </table>
  );
}

export default OrderTable;
