import React, {Dispatch} from "react";
import {useState} from 'react';

type FilterCallback = (text: string, filterOnField: string[], id: number) => void;
type Props = {
    filter: FilterCallback,
    id: number,
    filterOnField: string[],
    placeholderName: string
};


const handleChange = (text: string, setFilterInput: Dispatch<React.SetStateAction<string>>, filter: FilterCallback, id: number, filterOnField: string[]) => {
    setFilterInput(text);
    filter(text, filterOnField, id);
}
const FilterUI = (props: Props) => { 
    const [filterInput, setFilterInput] = useState<string>('');
    return (
    <div className="filterBar">
        <input type="text" value={filterInput} onChange={e => handleChange(e.target.value, setFilterInput, props.filter, props.id, props.filterOnField)} placeholder={props.placeholderName} name="filter"/>
    </div>)
}



export default FilterUI;