import React, { Component } from "react";
import Database from "../components/database/Database";
import PurchaseOrderForm from "../components/newOrder/NewPurchaseOrderForm";
import FileUploader from "../components/fileUpload/FileUpload";
import firebase from "../components/firebase/Firebase";
import FileDisplay from "../components/fileDisplay/FileDisplay";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from 'react-bootstrap/Card'
import ListItems from "../components/newOrder/ListItems";
import Address from "../components/address/Address";
import DisplayOrderItems from "../components/displayOrder/DisplayOrderItems";

class NewPurchaseOrder extends Component {
  constructor() {
    super();
    this.db = new Database();
  }
  state = {
    orderer: "",
    orderDetails: "",
    projectNumber: "",
    purchaseNumber: "",
    submittedDate: "",
    orderedDate: "",
    receivedShop: "",
    willCall: "",
    receivedField: "",
    completedDate: "",
    issue: "",
    neededDate: "",
    status: "",
    ordered: false,
    vendor: "",
    description: "",
    orderNotCompleted: true,
    shippingAddress: "",
    shippingInstructions: "",
    materialReq: "",
    cost: "",
    urls: "",
    isLoaded: false,
    userLoaded: false,
    purchaseOrderNum: 0,
    user: null,
    orderItems: []
  };

  passURL = (urls) => {
    this.setState({ urls: urls });
    this.setState({ materialRequestURL: urls });
  };

  passProjectNumber = (projectNumber) => {
    this.setState({ projectNumber: projectNumber });
  };

  updateState = (key, value) => {
    let state = this.state;
    state[key] = value;
    this.setState({state: state});
  }

  async componentDidMount() {
    
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({user: user, userLoaded: true});
      this.db.startPurchaseOrder(
        this.props.location.state.projectNumber,
        this.props.location.state.materialRequest,
        user.displayName.split(" ")[0][0] +
          user.displayName.split(" ")[1][0]
      );
    });
    const project = this.db.getProject(this.props.location.state.projectNumber);
    project.then( project => this.setState({ purchaseOrderNum: project.purchaseNumber+1}))
    this.db.getMaterialRequest(
      this.props.location.state.projectNumber,
      this.props.location.state.materialRequest,
      (order) => {
        this.setState(...order);
        this.setState({ isLoaded: true });
      }
    );
    
  }

  addOrRemoveOrderItem = (item) => {
    if(parseInt(item.qty) === 0 || item.qty === ''){
      let items = this.state.orderItems.filter((value, index, arr) => {
        return value.id !== item.id;
      })
      this.setState({orderItems: items})
    }
    else {
      let orderItems =  this.state.orderItems;
      if(this.state.orderItems.indexOf(item) === -1){
        orderItems.push(item);
      }
      else{
        let orderItems = this.state.orderItems.filter((value, index, arr) => {
          return value.id !== item.id;
        })
        orderItems.push(item);
      }
      this.setState({orderItems: orderItems});
    }
  }

  submitOrder = () => {
    let db = new Database();
      let address = new Address();
      address.street = this.state.shippingAddress.street;
      address.city = this.state.shippingAddress.city;
      address.state = this.state.shippingAddress.state;
      address.zipCode = this.state.shippingAddress.zipCode;
      address.country = "USA";
      firebase.auth().onAuthStateChanged((user) => {
        db.createPurchaseOrder(
          this.state.projectNumber,
          user.displayName.split(" ")[0][0] + user.displayName.split(" ")[1][0],
          {
            orderer: this.state.orderer,
            orderDetails: this.state.orderDetails,
            projectNumber: this.state.projectNumber,
            purchaseNumber: 0,
            submittedDate: this.state.submittedDate,
            orderedDate: new Date().toLocaleString(),
            receivedShop: "",
            willCall: "",
            receivedField: "",
            completedDate: "",
            issue: "",
            neededDate: this.state.neededDate,
            status: "Ordered",
            ordered: true,
            vendor: this.state.vendor,
            description: this.state.description,
            orderNotCompleted: true,
            shippingAddress: Object.assign({}, address),
            shippingInstructions: this.state.shippingInstructions,
            materialReq: this.state.materialReq,
            cost: this.state.cost,
            materialRequestURL:
            this.state.urls !== ""
                ? this.state.urls
                : this.state.materialRequestURL,
            orderItems: this.state.orderItems,
            
          }
        );
      });
      this.goBack();
  }

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div className="PurchaseOrder">
        <Button onClick={this.goBack}>Back</Button>
        <h2>New Purchase Order</h2>
        {(this.state.isLoaded && this.state.userLoaded && this.state.purchaseOrderNum) ? (
          <div>
            <Row>
              <Col>
                <PurchaseOrderForm
                  state={this.state}
                  history={this.props.history}
                  packingSlipURL={this.state.url}
                  updateState={this.updateState}
                />
                <Button onClick={this.submitOrder}>Create Purchase Order</Button>
              </Col>
              <Col>
                <FileUploader
                  projectNumber={this.state.projectNumber}
                  urls={this.state.materialRequestURL}
                  passCallback={this.passURL}
                />
                <Card>
                  <Card.Header>Attachments</Card.Header>
                  <Card.Body><FileDisplay  
                    passCallback={this.passURL}
                    urls={this.state.materialRequestURL}
                    allowDelete={true}
                  /></Card.Body>
                </Card>
                <Card>
                  <Card.Header>Order</Card.Header>
                  <Card.Body>
                    <ListItems items={this.state.orderItems} callBack={this.addOrRemoveOrderItem}/>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header>Order Preview</Card.Header>
                  <Card.Body>
                    <div>Safety</div>
                    <DisplayOrderItems orderDetailsItems={this.state.orderItems}/>
                    <div>{this.state.orderDetails}</div>
                    <div>Deliver to: {this.state.shippingAddress.street} {this.state.shippingAddress.city} {this.state.shippingAddress.state} {this.state.shippingAddress.zipCode}</div>
                    <div>Delivery Instructions: {this.state.shippingInstructions}</div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  }
}

export default NewPurchaseOrder;
