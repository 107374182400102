import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Database from "../components/database/Database";
import DetailedOrderTable from "../components/detailedOrderTable/DetailedOrderTable";
import Sort from "../sort/Sort";
import filter from "../filter/Filter";
import { SortDirection } from "../sort/SortDirection";
import { SortedBy } from "../sort/SortedBy";
import "./PurchasingView.css";




class PurchasingView extends Component {
  db: Database;

  constructor(props: any) {
    super(props)
    this.db = new Database();
  }
  state = {
    materialRequests: [],
    materialRequestsSorted: new SortedBy(),
    purchaseOrders: [],
    purchaseOrdersRestore: [],
    purchaseOrdersSorted: new SortedBy(),
    purchaseOrdersWithIssues: [],
    purchaseOrdersWithIssuesSorted: new SortedBy(),
    completedPurchaseOrders: [],
    completedPurchaseOrdersRestore: [],
    completedPurchaseOrdersSorted: new SortedBy(),
    completedMaterialRequests: [],
    completedMaterialRequestsRestore: [],
    completedMaterialRequestsSorted: new SortedBy(),
    showCompletedOrders: false,
    showCompleteMaterialRequests: false,
  };
  async componentDidMount() {
    this.db.getOpenMaterialRequests((openMaterialRequests: any) => {
      this.setState({ materialRequests: openMaterialRequests });
    });
    this.db.getPurchaseOrders((openPurchaseOrders: any) => {
      this.setState({ purchaseOrders: openPurchaseOrders });
      this.setState({ purchaseOrdersRestore: openPurchaseOrders });
    });
    this.db.getPurchaseOrdersWithIssues((ordersWithIssues: any) => {
      this.setState({ purchaseOrdersWithIssues: ordersWithIssues });
    });
  }

  getCompletedMaterialRequests = () => {
    this.db.getCompletedMaterialRequests((completeMaterialRequests: any) => {
      this.setState({
        completedMaterialRequests: completeMaterialRequests,
        showCompleteMaterialRequests: true,
      });
      this.setState({completedMaterialRequestsRestore: completeMaterialRequests});
    });
  };

  getCompletedPurchaseOrders = () => {
    this.db.getCompletePurchaseOrders((completePurchaseOrders: any) => {
      this.setState({
        completedPurchaseOrders: completePurchaseOrders,
        showCompletedOrders: true,
      });
      this.setState({completedPurchaseOrdersRestore: completePurchaseOrders});
    });
  };

  closeMaterialRequest = (projectNumber: string, materialReq: string, e: any) => {
    e.preventDefault();
    this.db.closeMaterialRequest(projectNumber, materialReq, () => {
      this.db.getOpenMaterialRequests((openMaterialRequests: any) => {
        this.setState({ materialRequests: openMaterialRequests });
      });
    });
  };

  changeSortOrder(propertyToSortBy: string, sortedBy: SortedBy) {
    let sorted = new SortedBy()
    if (propertyToSortBy === "submittedDate") {
      if (sortedBy.submittedDate === SortDirection.Asce) {
        sorted.submittedDate = SortDirection.Desc;
      } else {
        sorted.submittedDate = SortDirection.Asce;
      }
    } else if (propertyToSortBy === "neededDate") {
      if (sortedBy.neededDate === SortDirection.Asce) {
        sorted.neededDate = SortDirection.Desc;
      } else {
        sorted.neededDate = SortDirection.Asce;
      }
    } else if (propertyToSortBy === "orderedDate") {
      if (sortedBy.orderedDate === SortDirection.Asce) {
        sorted.orderedDate = SortDirection.Desc;
      } else {
        sorted.orderedDate = SortDirection.Asce;
      }
    } else if (propertyToSortBy === "orderer") {
      if (sortedBy.orderer === SortDirection.Asce) {
        sorted.orderer = SortDirection.Desc;
      } else {
        sorted.orderer = SortDirection.Asce;
      }
    }
    return sorted;
  }

  sortOrders = (id: number, propertyToSortBy: string ) => {
    if (id === 0) {
      this.setState({
        materialRequests: Sort(
          this.state.materialRequests,
          this.state.materialRequestsSorted[propertyToSortBy as keyof SortedBy] === SortDirection.Asce
            ? SortDirection.Desc
            : SortDirection.Asce,
          propertyToSortBy
        ),
      });
      this.setState({
        materialRequestsSorted: this.changeSortOrder(
          propertyToSortBy,
          this.state.materialRequestsSorted
        ),
      });
    } else if (id === 1) {
      this.setState({
        completedMaterialRequests: Sort(
          this.state.completedMaterialRequests,
          this.state.completedMaterialRequestsSorted[propertyToSortBy as keyof SortedBy] === SortDirection.Asce
          ? SortDirection.Desc
          : SortDirection.Asce,
          propertyToSortBy
        ),
      });
      this.setState({
        completedMaterialRequestsSorted: this.changeSortOrder(
          propertyToSortBy,
          this.state.completedMaterialRequestsSorted
        ),
      });
    } else if (id === 2) {
      this.setState({
        purchaseOrdersWithIssues: Sort(
          this.state.purchaseOrdersWithIssues,
          this.state.purchaseOrdersWithIssuesSorted[propertyToSortBy as keyof SortedBy] === SortDirection.Asce
          ? SortDirection.Desc
          : SortDirection.Asce,
          propertyToSortBy
        ),
      });
      this.setState({
        purchaseOrdersWithIssuesSorted: this.changeSortOrder(
          propertyToSortBy,
          this.state.purchaseOrdersWithIssuesSorted
        ),
      });
    } else if (id === 3) {
      this.setState({
        purchaseOrders: Sort(
          this.state.purchaseOrders,
          this.state.purchaseOrdersSorted[propertyToSortBy as keyof SortedBy] === SortDirection.Asce
          ? SortDirection.Desc
          : SortDirection.Asce,
          propertyToSortBy
        ),
      });
      this.setState({
        purchaseOrdersSorted: this.changeSortOrder(
          propertyToSortBy,
          this.state.purchaseOrdersSorted
        ),
      });
    } else if (id === 4) {
      this.setState({
        completedPurchaseOrders: Sort(
          this.state.completedPurchaseOrders,
          this.state.completedPurchaseOrdersSorted[propertyToSortBy as keyof SortedBy] === SortDirection.Asce
          ? SortDirection.Desc
          : SortDirection.Asce,
          propertyToSortBy
        ),
      });
      this.setState({
        completedPurchaseOrdersSorted: this.changeSortOrder(
          propertyToSortBy,
          this.state.completedPurchaseOrdersSorted
        ),
      });
    }
  };

  filterOrders = (filterText: string, filterOnField: string[], id: number) => {
    if(id === 1){
      this.setState({completedMaterialRequests: filter(this.state.completedMaterialRequestsRestore, filterOnField, filterText)});
    }
    if(id === 3){
      this.setState({purchaseOrders: filter(this.state.purchaseOrdersRestore, filterOnField, filterText)});
    }
    if(id === 4){
      this.setState({completedPurchaseOrders: filter(this.state.completedPurchaseOrdersRestore, filterOnField, filterText)});
    }
  }

  render() {
    return (
      <div className="OrdersView">
        <div className="TableContainer materialRequestContainer">
          <h2>Material Requests</h2>
          <DetailedOrderTable
            className="materialRequestTable"
            orders={this.state.materialRequests}
            materialTable={true}
            closeMaterialRequestHandler={this.closeMaterialRequest}
            path="/material-requests/"
            id={0}
            sortedBy={this.state.materialRequestsSorted}
            sort={this.sortOrders}
          />
        </div>

        {this.state.showCompleteMaterialRequests ? (
          <div>
            <h2>Completed Material Requests</h2>
            <DetailedOrderTable
              orders={this.state.completedMaterialRequests}
              materialTable={true}
              closeMaterialRequestHandler={this.closeMaterialRequest}
              path="/material-requests/"
              id={1}
              sortedBy={this.state.completedMaterialRequestsSorted}
              sort={this.sortOrders}
              filter={this.filterOrders}
            />
          </div>
        ) : null}
        <Button
          variant="outline-primary"
          onClick={this.getCompletedMaterialRequests}
        >
          Show Completed Material Requests
        </Button>
        <div className="TableContainer issueContainer">
          <h2>Orders With Issues</h2>
          <DetailedOrderTable
            orders={this.state.purchaseOrdersWithIssues}
            materialTable={false}
            path="/purchase-orders/"
            id={2}
            sortedBy={this.state.purchaseOrdersWithIssuesSorted}
            sort={this.sortOrders}
          />
        </div>
        <div className="TableContainer purchaseOrderContainer">
          <h2>Open Purchase Orders</h2>
          <DetailedOrderTable
            orders={this.state.purchaseOrders}
            materialTable={false}
            path="/purchase-orders/"
            id={3}
            sortedBy={this.state.purchaseOrdersSorted}
            sort={this.sortOrders}
            filter={this.filterOrders}
          />
        </div>

        {this.state.showCompletedOrders ? (
          <div>
            <h2>Completed Orders</h2>
            <DetailedOrderTable
              orders={this.state.completedPurchaseOrders}
              materialTable={false}
              path="/purchase-orders/"
              id={4}
              sortedBy={this.state.completedPurchaseOrdersSorted}
              sort={this.sortOrders}
              filter={this.filterOrders}
            />
          </div>
        ) : null}
        <Button
          variant="outline-primary"
          onClick={this.getCompletedPurchaseOrders}
        >
          Show Completed Orders
        </Button>
        
      </div>
    );
  }
}

export default PurchasingView;
