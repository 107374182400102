import React, { Component } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import firebase from "../firebase/Firebase";
import userContext from "../../userContext";

class SignIn extends Component {
  state = {
    user: null,
    userName: "",
  };

  signOn(e) {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(function () {
        var provider = new firebase.auth.OAuthProvider("microsoft.com");
        provider.setCustomParameters({
          tenant: "80513e82-145a-4d35-bd7b-54605efa213f",
          prompt:'select_account'
        });
        return firebase.auth().signInWithPopup(provider);
      });
  }
  
  async componentDidMount() {
    firebase.auth().onAuthStateChanged((userLoggedIn) => {
      if (userLoggedIn) {
        this.user = userLoggedIn;
        this.userName = userLoggedIn.displayName;
        this.setState({ user: this.user.displayName, userName: this.userName });
      }
    });
  }

  signOut = () => {
    firebase.auth().signOut();
    this.setState({userName: "Sign In"});

  };
  render() {
    return (
      <div>
        {this.user == null ? (
            <Button onClick={this.signOn} variant="success">Sign In</Button>
        ) : (
          <Dropdown>
            <Dropdown.Toggle variant="info" id="dropdown-basic">
              {this.state.userName}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.signOut}>Sign Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    );
  }
}
SignIn.contextType = userContext;
export default SignIn;
