import firebase from "../components/firebase/Firebase";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
function signOn(e) {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(function () {
      var provider = new firebase.auth.OAuthProvider("microsoft.com");
      provider.setCustomParameters({
        tenant: "80513e82-145a-4d35-bd7b-54605efa213f",
        prompt:'select_account'
      });
      return firebase.auth().signInWithRedirect(provider);
    });
}
firebase
  .auth()
  .getRedirectResult()
  .then(function (result) {
    // User is signed in.
    // IdP data available in result.additionalUserInfo.profile.
    // OAuth access token can also be retrieved:
    // result.credential.accessToken
    // OAuth ID token can also be retrieved:
    // result.credential.idToken
  })
  .catch(function (error) {});

class SignInView extends Component {
  render() {
    return (
      <Row>
        <Col>
          <Button onClick={signOn} variant="primary">
            Login
          </Button>{" "}
        </Col>
      </Row>
    );
  }
}

export default SignInView;
