import React from "react";
import "./DisplayOrder.css"

type Props = {
    orderDetails: string,
}

export default function DisplayOrder(props: Props) {
    if(props.orderDetails === ""){
        return "None";
    }
    else {
        return (
            <ul>
                {props.orderDetails.split('\n').map((line: string, index: number) => (
                    <li key={index}>
                        {line}
                    </li>
                ))}
            </ul>
        )
    }
    
}