import React, { Component } from "react";
import "./OrderDetail.css";
import Database from "../components/database/Database";
import Order from "../components/order/Order";
import FileDisplay from "../components/fileDisplay/FileDisplay";
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DisplayOrder from "../components/displayOrder/DisplayOrder";
import DisplayOrderItems from "../components/displayOrder/DisplayOrderItems";
class MaterialRequestDetail extends Component {
  constructor(props) {
    super(props);
    this.db = new Database();
    this.projectNumber = this.props.location.pathname
      .split("/")[2]
      .split("-")[0];

    let materialReqAndPurchaseNumber = this.props.location.pathname
      .split("/")[2]
      .split("-");
    this.materialReq = materialReqAndPurchaseNumber[1];
    if (materialReqAndPurchaseNumber.length > 2) {
      this.purchaseNumber = materialReqAndPurchaseNumber[2];
    } else {
      this.purchaseNumber = 0;
    }
  }
  state = {
    ...new Order(),
    urls: []
  };

  async componentDidMount() {
    this.db.getMaterialRequestDetail(
      this.projectNumber,
      this.materialReq,
      this.purchaseNumber,
      (order) => {
        this.setState(...order);
      }
    );
  }

  passURL = (urls) => {
    this.setState({ urls: urls });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="OrderDetail">
        <Button onClick={this.goBack}>Back</Button>
        <Card>
          <Card.Header>Material Request {this.state.projectNumber}-{this.state.materialReq}</Card.Header>
          <Card.Body>
              <Row>
                <Col>
                  Request Status: 
                </Col>
                <Col>{this.state.status}</Col>
              </Row>
              <Row>
                <Col>
                  Submitted Date: 
                </Col>
                <Col>{new Date(this.state.submittedDate).toLocaleString('en-US', {timeZone: "PST"})}</Col>
              </Row>
              <Row>
                <Col>
                Date Needed: 
                </Col>
                <Col>{new Date(this.state.neededDate).toLocaleDateString('en-US', {timeZone: "UTC"})}</Col>
              </Row>
              <Row>
                <Col>
                Short Description: 
                </Col>
                <Col>{this.state.description}</Col>
              </Row>
              <Row>
                <Col>
                Requested by: 
                </Col>
                <Col>{this.state.orderer}</Col>
              </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Order Details</Card.Header>
          <Card.Body>
            <Row>
                <Col>
                Order Details: 
                </Col>
                <Col>
                  <DisplayOrder orderDetails={this.state.orderDetails}/>
                </Col>
              </Row>
              <Row>
                <Col>
                Order: 
                </Col>
                <Col>
                  <DisplayOrderItems orderDetailsItems={this.state.orderItems}/>
                </Col>
              </Row>
              <Row>
                <Col>
                Attachments:  
                </Col>
                <Col><FileDisplay 
                  passCallback={this.passURL}
                  urls={this.state.materialRequestURL}
                  allowDelete={false}/>
                </Col>
              </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Shipping Information</Card.Header>
          <Card.Body>
            <Row>
                <Col>
                Shipping Address:
                </Col>
                <Col>{`${this.state.shippingAddress.street} ${this.state.shippingAddress.city}, ${this.state.shippingAddress.state} ${this.state.shippingAddress.zipCode} ${this.state.shippingAddress.country}`}</Col>
              </Row>
              <Row>
                <Col>
                Shipping Instructions: 
                </Col>
                <Col>{this.state.shippingInstructions}</Col>
              </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default MaterialRequestDetail;