import firebase from "firebase";
import "firebase/firestore";
import "firebase/database";

const config = {
  apiKey: "AIzaSyCpy8KbU-k-flqGwURI9lYWrPLsXkf8dOk",
  authDomain: "procurement.agcinc.com",
  databaseURL: "https://procurement-b6765.firebaseio.com",
  projectId: "procurement-b6765",
  storageBucket: "procurement-b6765.appspot.com",
  messagingSenderId: "1036282413401",
  appId: "1:1036282413401:web:ff48609b5c5db34c5ecc48",
  measurementId: "G-L5L4LB2VNF"
};


firebase.initializeApp(config);

export default firebase;
