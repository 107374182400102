import {SortDirection} from './SortDirection';
import Order from './../components/order/Order';

const Sort = (orders: Order[] , sortOrder: SortDirection, sortBy: string) => {
    if(typeof orders[0][sortBy as keyof Order] === "string"){
        orders.sort((firstOrder: Order, secondOrder: Order) => {
            if(sortOrder === SortDirection.Asce){
                return ascendString(firstOrder[sortBy as keyof Order] as string, secondOrder[sortBy as keyof Order] as string);
            }
            else{
                return descendString(firstOrder[sortBy as keyof Order] as string, secondOrder[sortBy as keyof Order] as string);
            }
        })
    }
    else if(orders[0][sortBy as keyof Order] instanceof Date){
        orders.sort((first: Order, second: Order) => {
        if (sortOrder === SortDirection.Asce) {
             return ascendDate(first[sortBy as keyof Order] as Date, second[sortBy as keyof Order] as Date);
        } else {
            return descendDate(first[sortBy as keyof Order] as Date, second[sortBy as keyof Order] as Date);
        }
        });
    }
    return orders;
}

export const ascendDate = (firstDate: Date, secondDate: Date) => {
    return firstDate.valueOf() - secondDate.valueOf();
}

export const descendDate = (firstDate: Date, secondDate: Date) => {
    return secondDate.valueOf() - firstDate.valueOf();
}

export const ascendString = (firstString: string, secondString: string) => {
    return firstString.localeCompare(secondString);
}

export const descendString = (firstString: string, secondString: string) => {
  return secondString.localeCompare(firstString);
};

export default Sort;