import React from "react";
import "./DisplayOrder.css"

type Props = {
    orderDetailsItems: any,
}

export default function DisplayOrderItems(props: Props) {
    if(props.orderDetailsItems === null || props.orderDetailsItems === []){
        return "None";
    }
    else {
        return (
            <ul>
                {props.orderDetailsItems.map((item: any, index: number) => (
                    <li key={index}>
                        {item.qty} - {item.name}
                    </li>
                ))}
            </ul>
        )
    }
    
}