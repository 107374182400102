import React from "react";
import "./OrderTimeLine.css";

type Props = {
  completedClass: string,
  label: string,
  date: string,
}

function OrderTimeLine(props: Props) {
  let orderTimeLine = (
    <div className="OrderTimeLine">
      <label className={props.completedClass}>
        {props.label + " " + props.date}
      </label>
    </div>
  );
  return orderTimeLine;
}

export default OrderTimeLine;
