import React, {FC} from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import userContext from "../../userContext";

interface Props {
  path: string,
  component: any,
}

const  ProtectedRoute: FC<Props> = ({ children, ...rest }) => {
  let isAuthenticated = false;
  const context = React.useContext(userContext);
  let user = context.user
  if (user) {
    isAuthenticated = true;
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default ProtectedRoute;
