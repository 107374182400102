import React from 'react';

type Props = {
    items: any;
    callBack: any;
}

 const ListItems = (props: Props) => {
    let orderLines = props.items.length > 0 ? props.items.map((item: any)  => {
        return <div key={item.id}><input type="number" id="qty" min="0" max="100000" defaultValue={item.qty > 0 ? item.qty: ""}  onChange={(e) => {
            item.qty = e.target.value;
            props.callBack(item);
        } 
    }/> - <span>{item.name}</span></div>
    }) : null;
    return(
        <div>{orderLines}</div>
    );
 }

 export default ListItems;