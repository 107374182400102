import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Database from "../database/Database";

import "../customButton/CustomButton.css";
class OrderIssues extends Component {
  constructor(props) {
    super(props);
    this.db = new Database();
    this.damaged = false;
    this.incorrectItem = false;
    this.backOrder = false;
    this.missing = false;
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
    this.setState({orderIssueDetail: event.target.value});
  }

  parseOrderString = (orderIssues, key) => {
    return orderIssues.includes(key);
  };
  state = {
    damaged: false,
    incorrectItem: false,
    backOrder: false,
    missing: false,
    orderIssueDetail: ""
  };

  async componentDidMount() {
    this.db.getOrderDetail(
      this.props.projectNumber,
      this.props.materialReq,
      this.props.purchaseNumber,
      (order) => {
        this.damaged = this.parseOrderString(order.issue, "D");
        this.incorrectItem = this.parseOrderString(order.issue, "I");
        this.backOrder = this.parseOrderString(order.issue, "B");
        this.missing = this.parseOrderString(order.issue, "M");
        this.setState({
          damaged: this.damaged,
          incorrectItem: this.incorrectItem,
          backOrder: this.backOrder,
          missing: this.missing,
          orderIssueDetail: order.orderIssueDetail ? order.orderIssueDetail : "",
        });
        this.setState({ updated: true });
      }
    );
  }

  saveIssueDetail = () => {

  }

  updateIssue = (key) => {
    switch (key) {
      case "M":
        this.missing = !this.missing;
        this.setState(
          { missing: this.missing },
          this.updateIssueInDatabase()
        );
        break;
      case "D":
        this.damaged = !this.damaged;
        this.setState(
          { damaged: this.damaged },
          this.updateIssueInDatabase()
        );
        break;
      case "I":
        this.incorrectItem = !this.incorrectItem;
        this.setState(
          { incorrectItem: this.incorrectItem },
          this.updateIssueInDatabase()
        );
        break;
      case "B":
        this.backOrder = !this.backOrder;
        this.setState(
          { backOrder: this.backOrder },
          this.updateIssueInDatabase()
        );
        break;
      default:
        
    }
    //TODO does not update the values in state, before the function call
  };

  updateIssueInDatabase = () => {
    this.db.updateOrderIssues(
      this.props.projectNumber,
      this.props.materialReq,
      this.props.purchaseNumber,
      this.createIssueString(
        this.incorrectItem,
        this.damaged,
        this.backOrder,
        this.missing
      ),
      this.state.orderIssueDetail
    );
  };

  createIssueString = (incorrect, damaged, backOrder, missing) => {
    let issues = "";
    if (incorrect) {
      issues = issues.concat("I");
    }
    if (damaged) {
      issues = issues.concat("D");
    }
    if (backOrder) {
      issues = issues.concat("B");
    }
    if (missing) {
      issues = issues.concat("M");
    }
    return issues;
  };

  render() {
    return (
      <div>
        <h4>Issues</h4>
        <Row>
          <Col>
            <button
              onClick={(e) => this.updateIssue("M")}
              className={(this.state.missing=== true)? "selectedButton" : "unselectedButton"}
            >
              Missing
            </button>
            <button
              onClick={(e) => this.updateIssue("D")}
              className={(this.state.damaged=== true)? "selectedButton" : "unselectedButton"}
            >
              Damaged
            </button>
            <button
              onClick={(e) => this.updateIssue("I")}
              className={(this.state.incorrectItem=== true)? "selectedButton" : "unselectedButton"}
            >
              Incorrect
            </button>
            <button
              onClick={(e) => this.updateIssue("B")}
              className={(this.state.backOrder=== true)? "selectedButton" : "unselectedButton"}
            >
              Back Order
            </button>
          </Col>
        </Row>
        {
        (this.state.damaged || this.state.incorrectItem || this.state.backOrder || this.state.missing ?
        <div>
          <Row>
          <Col>
            <label htmlFor="issueDetail">Details of issues with PO</label>
          </Col>
        </Row>
        <Row>
          <Col>
            <textarea id="issueDetail" value={this.state.orderIssueDetail} rows="5" cols="40" onChange={this.handleChange} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              varient="primary"
              onClick={this.updateIssueInDatabase}
            >
              Save
            </Button>
          </Col>
        </Row>
        </div> : null
        )
        }
        
      </div>
    );
  }
}

export default OrderIssues;
