class Address {
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  constructor() {
    this.street = "";
    this.city = "";
    this.state = "CA";
    this.zipCode = "";
    this.country = "USA";
  }
}

export default Address;
