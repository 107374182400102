import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import userStateContext from "./userContext";
import firebase from "./components/firebase/Firebase";
import MainView from "./containers/MainView";

import "./App.css";

class App extends Component<any>{
  userState: any;
  constructor(props: any) {
    super(props);
    this.userState = {
      user: null,
    };
  }
  state = {
    authReturned: false,
  };
  async componentDidMount() {
    firebase.auth().onAuthStateChanged((userLoggedIn) => {
      if(userLoggedIn){
        userLoggedIn.getIdTokenResult().then(idTokenResult => {
      })
      }
      this.userState.user = userLoggedIn;
      this.setState({ authReturned: true });
    });
  }

  updateUser = (user: any) => {
    this.userState.user = user;
  };
  render() {
    return this.state.authReturned ? (
      <userStateContext.Provider
        value={{user: this.userState.user}}
      >
        <BrowserRouter>
          <MainView />
        </BrowserRouter>
      </userStateContext.Provider>
    ) : (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }
}

export default App;
