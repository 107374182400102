import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Database from "../../components/database/Database";
import NewMaterialRequestForm from "../../components/newOrder/NewMaterialRequestForm";
import NewMaterialRequestItems from "../../components/newOrder/NewMaterialRequestItems";
import FileUploader from "../../components/fileUpload/FileUpload";
import firebase from "firebase";
import {useNewOrderList} from "../../FeatureFlag";

import "./DirectOrder.css";
import FileDisplay from "../../components/fileDisplay/FileDisplay";
import Address from "../../components/address/Address";
class DirectOrder extends Component {
  constructor() {
    super();
    this.db = new Database();
    this.draftOrder = {
      email: "",
      project: "",
      projectName: "",
      materialReq: "",
      submittedDate: "",
      neededDate: "",
      attachByEmail: false,
      status: "Draft",
      ordered: false,
      shortDescription: "",
      order: "",
      street: "",
      city: "",
      state: "CA",
      zipCode: "",
      shippingInstructions: "",
    }
  }

  state = {
    draftID: null,
    projectNumber: "",
    urls: [],
    projectAddress: new Address(),
    projectName: "",
    validData: true,
    projectNames: [],
    orderItems: [],
    safetyItems: [],
  };

  //TODO finish draft order initialization
  async componentDidMount() {
    let projectNames = await this.db.getProjectNames();
    let orderItems = await this.db.getOrderItems();
    let id = this.props.location.pathname.split('/')[2];
    let draft = null;
    this.setState({ projectNames: projectNames });
    if(id !== undefined){
      draft = await this.db.getDraftMaterialRequest(id);
      let draftState = this.state.draftMR;
      draftState.email = draft.orderer;
      draftState.project = draft.projectNumber;
      draftState.neededDate = draft.neededDate;
      draftState.status = draft.status;
      draftState.shortDescription = draft.description;
      draftState.order = draft.orderDetails;
      draftState.shippingInstructions = draft.shippingInstructions;
      draftState.street = draft.shippingAddress.street;
      draftState.city = draft.shippingAddress.city;
      draftState.zipCode = draft.shippingAddress.zipCode;
      this.draftOrder = draftState;
      this.setState({orderItems: draft.orderItems});
      this.setState({draftID: id});
      this.setState({projectAddress: draft.shippingAddress})
      this.setState({projectNumber: draft.projectNumber});
      this.validateExistingProjectNumber(draft.projectNumber);
      for(let i = 0; i < draft.orderItems.length; i++){
        let index = orderItems.safety.items.findIndex(element => {
          return element.id === draft.orderItems[i].id;
        });
        orderItems.safety.items[index].qty = draft.orderItems[i].qty;
      }
    }
    this.setState({safetyItems: orderItems.safety.items});    
  }

  validateExistingProjectNumber = (projectNumber) => {
    if (projectNumber.toUpperCase() in this.state.projectNames) {
      let name = this.state.projectNames[projectNumber.toUpperCase()];
      let draft = this.draftOrder;
      draft.projectName = name;
      this.setState({
        projectName: name,
      });
      this.draftOrder = draft;
    }
  };

  updateDraftMR = (key, value) => {
    let draft = this.draftOrder;
    draft[key] = value;
    //let validData = draft.project.match(/[0-9]{2}[a-zA-Z][0-9]{3}$/);
    this.draftOrder = draft;

  }

  passURL = (urls) => {
    this.setState({ urls: urls });
  };

  passProjectNumber = (projectNumber) => {
    this.getProjectAddress(projectNumber);
    this.setState({ projectNumber: projectNumber });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  saveDraft = () => {
    firebase.auth().onAuthStateChanged((user) => {
      let db = new Database();
      let address = new Address();
      address.street = this.draftOrder.street;
      address.city = this.draftOrder.city;
      address.state = this.draftOrder.state;
      address.zipCode = this.draftOrder.zipCode;
      address.country = "USA";
      if (user && user.email && user.displayName) {
        db.createDraftMaterialRequest(
          user.email,
          this.draftOrder.project,
          this.draftOrder.neededDate,
          this.draftOrder.shortDescription,
          this.draftOrder.order,
          address,
          this.draftOrder.shippingInstructions,
          this.state.urls,
          this.state.orderItems,
          this.state.draftID,
        );
      }
      this.goBack();
    });
  }

  passDraftMaterialRequest = (MR) => {
    this.setState({draftMR: MR});
  }

  getProjectAddress = async (projectNumber) => {
    if (projectNumber !== "") {
      projectNumber = projectNumber.toUpperCase();
      let project = await this.db.getProject(projectNumber);
      let address = project.address;
      this.setState({ projectAddress: address });
      this.updateDraftMR('street', address.street);
      this.updateDraftMR('city', address.city);
      this.updateDraftMR('zipCode', address.zipCode);
    }
  };

  addOrRemoveOrderItem = (item) => {
    if(parseInt(item.qty) === 0 || item.qty === ''){
      let items = this.state.orderItems.filter((value, index, arr) => {
        return value.id !== item.id;
      })
      this.setState({orderItems: items})
    }
    else {
      let orderItems =  this.state.orderItems;
      if(this.state.orderItems.indexOf(item) === -1){
        orderItems.push(item);
      }
      else{
        let orderItems = this.state.orderItems.filter((value, index, arr) => {
          return value.id !== item.id;
        })
        orderItems.push(item);
      }
      this.setState({orderItems: orderItems});
    }
  }

  render() {
    return (
      <div className="DirectOrder">
        <div><Button variant="danger" onClick={this.goBack}>Back</Button></div>
        <Button onClick={this.saveDraft}>Back Save Draft</Button>

        <h2>Material Request</h2>
        <NewMaterialRequestForm
          fileURL={this.state.urls}
          passCallback={this.passProjectNumber}
          validateExistingProjectNumber={this.validateExistingProjectNumber}
          projectName={this.state.projectName}
          updateDraftMR={this.updateDraftMR}
          history={this.props.history}
          projectNumber={this.state.projectNumber}
          neededDate={this.draftOrder.neededDate}
          order={this.draftOrder.order}
          shortDescription={this.draftOrder.shortDescription}
          shippingInstructions={this.draftOrder.shippingInstructions}
          street={this.state.projectAddress.street}
          city={this.state.projectAddress.city}
          zip={this.state.projectAddress.zipCode}
        />
        <FileUploader
          projectNumber={this.state.projectNumber}
          storageLocation="documents"
          passCallback={this.passURL}
          urls={this.state.urls}
          urlType="MR"
        />

        <FileDisplay
          passCallback={this.passURL}
          urls={this.state.urls}
          urlType="MR"
          allowDelete={true}
        />
        
        {useNewOrderList ? 
          <NewMaterialRequestItems
            safetyItems={this.state.safetyItems}
            pipeItems={null}
            supports={null}
            duct={null}
            instrumentation={null}
            order={this.state.orderItems}
            addItemCallBack={this.addOrRemoveOrderItem}
          />
        :
          null
        }
        {
        !this.state.validData ? 
        <div>Complete form to continue</div>
         :
        <div>
          <Link to={{
            pathname: "/new-order-draft",
            state: {
              draftMR: this.draftOrder,
              orderItems: this.state.orderItems,
              orderURLS: this.state.urls,
              draftID: this.state.draftID
            }
          }
        }>Preview Order</Link>
        </div>
        }
      </div>
    );
  }
}
export default DirectOrder;
