import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebase from "../components/firebase/Firebase";
import OrderTable from "../components/orderTable/OrderTable";
import Database from "../components/database/Database";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class OrdersView extends Component {
  constructor() {
    super();
    this.db = new Database();

    this.state = {
      orderUserFilter: "All",
      projectFilter: "All",
      materialRequests: [],
      purchaseOrders: [],
      orders: [],
      completedPurchaseOrders: [],
      showCompletedOrders: false,
    };
  }

  async componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.db.getOpenMaterialRequestsForUser(user.email, (openOrders) => {
          this.setState({ materialRequests: openOrders });
        });
        this.db.getPurchaseOrdersForUser(user.email, (openOrders) => {
          this.setState({ purchaseOrders: openOrders });
        });
      } else {
        // User is signed out
        // ...
      }
    });
  }

  getCompletedPurchaseOrders = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.db.getCompletePurchaseOrdersForUser(
          user.email,
          (completePurchaseOrders) => {
            this.setState({
              completedPurchaseOrders: completePurchaseOrders,
              showCompletedOrders: true,
            });
          }
        );
      }
    });
  };

  filterByUser = (event) => {
    const value = event.target.value;
    this.setState(
      { orderUserFilter: value, orders: [...this.allOrders] },
      () => {
        if (value !== "All") {
          this.setState({
            orders: this.state.orders.filter(
              (order) => order.orderer === value
            ),
          });
        }
      }
    );
  };

  filterByProject = (event) => {
    const value = event.target.value;
    this.setState(
      {
        projectFilter: value,
        orders: [...this.allOrders],
      },
      () => {
        if (value !== "All") {
          this.setState({
            orders: this.state.orders.filter(
              (order) => order.project.substring(0, 6) === value
            ),
          });
        }
      }
    );
  };

  cancelMaterialRequest = (projectNumber, materialRequestNumber) => {
    this.db.cancelMaterialRequest(projectNumber, materialRequestNumber);
  }

  render() {
    return (
      <div className="OrdersView">
        <Row>
          <Col>
            <Link to={"direct-order"}>
              <Button variant="success">+ Create New Material Request</Button>{" "}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Orders Log</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Material Requests</h3>
            <OrderTable
              orders={this.state.materialRequests}
              path="/material-requests/"
              materialTable={true}
              cancelMaterialRequest={this.cancelMaterialRequest}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Open Purchase Orders</h3>
            <OrderTable
              orders={this.state.purchaseOrders}
              path="/purchase-orders/"
              materialTable={false}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.showCompletedOrders ? (
              <div>
                <h2>Completed Orders</h2>
                <OrderTable
                  orders={this.state.completedPurchaseOrders}
                  path="/purchase-orders/"
                  materialTable={false}
                />
              </div>
            ) : (
              <Button
                variant="outline-primary"
                onClick={this.getCompletedPurchaseOrders}
              >
                Show Completed Orders
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default OrdersView;
