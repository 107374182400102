import firebase from "../firebase/Firebase";
import React, { Component } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Database from "../database/Database";

export function addIdentifierToFileName(fileName, optionalIdentifier){
  if(fileName === ""){
    return "Error No File";
  }
  let splitFile = fileName.split(".");
  let file = "";
  for(let i = 0; i < splitFile.length-1; i++){
    file = file.concat(splitFile[i]);
  }
  let identifier = "";
  if(optionalIdentifier){
    identifier = optionalIdentifier;
  }
  else{
    identifier = Date.now().toString(16);
  }
  return file + identifier + "." + splitFile[splitFile.length-1];
}

class FileUpload extends Component {
  constructor() {
    super();
    this.storage = firebase.storage();
    this.db = new Database();
    this.selectedFile = null;
    this.fileName = "";
  }
  state = {
    // Initially, no file is selected
    selectedFile: null,
    urls: [],
    isFileUploadInProgress: false,
    progress: 0,
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    if(event.target.files[0]){
      this.selectedFile = event.target.files[0];
      this.setState({ selectedFile: event.target.files[0] });
      for(let i = 0; i < event.target.files.length; i++){
        this.onFileUpload(event.target.files[i]);
      }
    }
  };

  // On file upload (click the upload button)
  onFileUpload = (file) => {
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "myFile",
      file,
      file.name
    );
    
    let storageRef = this.storage.ref(
      this.props.storageLocation + "/" + addIdentifierToFileName(file.name)
    );
    this.setState({ isFileUploadInProgress: true });
    let uploadTask = storageRef.put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        this.setState({
          progress: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        });
      },
      (error) => {},
      () => {
        let urls = this.props.urls;
        if(Array.isArray(urls) === false &&  urls.length > 0){
          urls = [urls];
        }
        let uploads = [...urls];
        this.setState({ isFileUploadInProgress: false });
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          uploads.push(downloadURL);
          this.props.passCallback(uploads, this.props.urlType);
          this.setState({ uploadedFile: uploads });
        });
      }
    );
  };

  render() {
    return (
      <div>
        <input type="file" multiple={true} onChange={this.onFileChange} />
        {this.state.isFileUploadInProgress ? (
          <ProgressBar now={this.state.progress} />
        ) : (
          " "
        )}
      </div>
    );
  }
}

export default FileUpload;
