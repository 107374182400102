import firebase from "../firebase/Firebase";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Database from "../database/Database";

import "./FileDisplay.css";
type callBack = (urls: Array<string>, urlType: string) => void;

type Props = {
  passCallback: callBack,
  urls: any,
  allowDelete: boolean,
  urlType: string
}

class FileDisplay extends Component<Props>  {
    storage: firebase.storage.Storage;
    db: Database;
    state: any;

    constructor(props: Props){
      super(props)
        this.storage = firebase.storage();
        this.db = new Database();
        this.state ={
            fileUrls: []
        }
    }

    convertToValidURLArray = (urls: string) => {
      return [urls];
    }

    removeFile = (url: string) => {
        let urls = this.props.urls
        if(Array.isArray(urls) && urls[0] !== ""){
             urls = urls.filter((value, index, arr) => {
                let result = false;
                if(value !== ""){
                  result = value.split("%2F")[1].split("?")[0] !== url.split("%2F")[1].split("?")[0]
                }
                return result;
            })
            this.setState({fileUrls: urls});
        }
        this.props.passCallback(urls, this.props.urlType);
      }

    


    render() {
          let urls = this.props.urls;
          if(Array.isArray(urls) === false &&  urls.length > 0){
            urls = this.convertToValidURLArray(urls);
          }
          if(Array.isArray(urls) && urls.length > 0 && this.props.urls[0] !== ""){
            let allowDelete = this.props.allowDelete;
            const files = urls.map((file, index) => (
                <li key={index}>
                    <a
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{file.split("%2F")[1].split("?")[0]}</a>
                  {(allowDelete) ?
                    <Button variant="danger" onClick={() => {this.removeFile(file)}}>
                        X
                    </Button>
                    : ""}
                </li>
            ));
            return (
              <ul className="FileList">
                  {files}
              </ul>
            );
          }
          
          else
          {
              return "None";
          }
        
      }
}

export default FileDisplay;