import Address from "../address/Address";
class Order {
  orderer: string;
  beingOrdered: string;
  projectNumber: string;
  purchaseNumber: number;
  materialReq: string;
  submittedDate: Date;
  orderedDate: Date;
  receivedShop: Date;
  willCall: string;
  receivedField: Date;
  completedDate: Date;
  issue: string;
  orderHasIssue: boolean;
  issueDate: Date;
  neededDate: Date;
  status: string;
  ordered: boolean;
  orderDetails: string;
  vendor: string;
  description: string;
  cost: number;
  orderNotCompleted: boolean;
  shippingAddress: Address;
  shippingInstructions: string;
  materialRequestURL: Array<string>;
  purchaseOrderURL: Array<string>;
  issueURL: Array<string>;
  orderConfirmationURL: string;
  packingSlipURL: string;
  orderItems: any;

  constructor() {
    this.orderer = "";
    this.beingOrdered ="";
    this.projectNumber = "";
    this.purchaseNumber = 0;
    this.materialReq = "";
    this.submittedDate = new Date("01/01/1901");
    this.orderedDate = new Date("01/01/1901");
    this.receivedShop = new Date("01/01/1901");
    this.willCall = "";
    this.receivedField = new Date("01/01/1901");
    this.completedDate = new Date("01/01/1901");
    this.issue = "";
    this.orderHasIssue = false;
    this.issueDate = new Date("01/01/1901");
    this.neededDate = new Date("01/01/1901");
    this.status = "";
    this.ordered = false;
    this.orderDetails = "";
    this.vendor = "";
    this.description = "";
    this.cost = 0;
    this.orderNotCompleted = true;
    this.shippingAddress = new Address();
    this.shippingInstructions = "";
    this.materialRequestURL = new Array<string>();
    this.purchaseOrderURL = new Array<string>();
    this.issueURL = new Array<string>();
    this.orderConfirmationURL = "";
    this.packingSlipURL = "";
    this.orderItems = [];
  }
}

export default Order;
