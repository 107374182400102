import React from "react";
import { Formik, Field } from "formik";
import firebase from "../firebase/Firebase";
import Database from "../database/Database";
import Address from "../address/Address";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as yup from "yup";

import "./NewOrderForm.css";

type Props = {
  fileURL: any;
  passCallback: any;
  validateExistingProjectNumber: any;
  updateDraftMR: any;
  projectName: any;
  history: any;
  projectNumber: string;
  neededDate: any;
  order: string;
  shippingInstructions: string;
  shortDescription: string;
  street: string;
  city: string;
  zip: string;
};

const schema = yup.object({
  project: yup
    .string()
    .required("Enter Job Number")
    .matches(/[0-9]{2}[a-zA-Z][0-9]{3}$/, "Example format 21Z001"),
  neededDate: yup.date().required(),
  shortDescription: yup.string().required(),
  shippingInstructions: yup.string().required(),
});

const NewMaterialRequestForm = (props: Props) => (
  <Formik
    enableReinitialize
    validationSchema={schema}
    initialValues={{
      project: props.projectNumber,
      materialReq: "",
      submittedDate: "",
      neededDate: props.neededDate,
      attachByEmail: false,
      status: "Submitted",
      ordered: false,
      shortDescription: props.shortDescription,
      order: props.order,
      street: props.street,
      city: props.city,
      state: "CA",
      zipCode: props.zip,
      shippingInstructions: props.shippingInstructions,
    }}
    onSubmit={(values) => {
      firebase.auth().onAuthStateChanged((user) => {
        let db = new Database();
        let address = new Address();
        address.street = values.street;
        address.city = values.city;
        address.state = values.state;
        address.zipCode = values.zipCode;
        address.country = "USA";

        if (user && user.email && user.displayName) {
          const createdOrder = db.createMaterialRequest(
            user.email,
            user.displayName.split(" ")[0][0] +
              user.displayName.split(" ")[1][0],
            values.project,
            values.neededDate,
            values.shortDescription,
            values.order,
            address,
            values.shippingInstructions,
            props.fileURL
          );
          if (values.attachByEmail) {
            createdOrder.then((order) => {
              window.location.href = `mailto:edgar@agcinc.com?cc=kgill@agcinc.com&subject=${`PROCUREMENT Attachment For ${order.projectNumber}-${order.materialReq} From ${user.displayName}`}`;
            });
          }
        }

        props.history.push("/");
      });
    }}
  >
    {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
    }) => (
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <div>{`Project Name ${props.projectName}`}</div>
          <Form.Label htmlFor="project">{"Project # "}</Form.Label>
          <Form.Control
            id="project"
            name="project"
            value={values.project}
            onChange={(e) => {
              handleChange(e);
              if(e.target.value.length === 6){
                props.validateExistingProjectNumber(e.target.value)
                props.updateDraftMR("project", e.target.value)
              }
            }}
            isInvalid={!!errors.project}
          />
          <Button
            onClick={() => {
              props.passCallback(values.project);
            }}
          >
            Load Project Address
          </Button>
        </Form.Group>

        <Form.Group>
          <Form.Label htmlFor="neededDate">Date Needed</Form.Label>
          <Form.Control
            id="neededDate"
            name="neededDate"
            type="date"
            value={values.neededDate}
            onChange={(e) => {
              handleChange(e);
              props.updateDraftMR("neededDate", e.target.value)
            }}
            isInvalid={!!errors.neededDate}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="ShortDescription">Short Description</Form.Label>
          <Form.Control
            as="textarea"
            id="shortDescription"
            name="shortDescription"
            type="text"
            value={values.shortDescription}
            onChange={(e) => {
              handleChange(e);
              props.updateDraftMR("shortDescription", e.target.value)
            }}
            isInvalid={!!errors.shortDescription}
            rows={1}
            cols={30}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="order">Order Details</Form.Label>
          <Form.Control
            as="textarea"
            id="order"
            name="order"
            type="text"
            value={values.order}
            onChange={(e) => {
              handleChange(e);
              props.updateDraftMR("order", e.target.value)
            }}
            rows={6}
            cols={40}
          />
        </Form.Group>
        <Form.Group>
          <Field type="checkbox" name="attachByEmail" />
          <Form.Label htmlFor="attachByEmail">
            Send Attachments By Email?
          </Form.Label>
        </Form.Group>
        <h5>Shipping Information</h5>
        <Form.Group>
          <Form.Label htmlFor="street">Street</Form.Label>
          <Form.Control
            as="textarea"
            id="street"
            name="street"
            type="text"
            value={values.street}
            onChange={(e) => {
              handleChange(e);
              props.updateDraftMR("street", e.target.value)
            }}
            isInvalid={!!errors.street}
            rows={2}
            cols={40}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="city">City</Form.Label>
          <Form.Control
            id="city"
            name="city"
            type="text"
            value={values.city}
            onChange={(e) => {
              handleChange(e);
              props.updateDraftMR("city", e.target.value)
            }}
            isInvalid={!!errors.city}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="state">State</Form.Label>
          <Form.Control
            id="state"
            name="state"
            value={values.state}
            onChange={(e) => {
              handleChange(e);
              props.updateDraftMR("state", e.target.value)
            }}
            isInvalid={!!errors.state}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="zipCode">ZIP Code</Form.Label>
          <Form.Control
            id="zipCode"
            name="zipCode"
            value={values.zipCode}
            onChange={(e) => {
              handleChange(e);
              props.updateDraftMR("zipCode", e.target.value)
            }}
            isInvalid={!!errors.zipCode}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="shippingInstructions">
            Shipping Instructions
          </Form.Label>
          <Form.Control
            as="textarea"
            id="shippingInstructions"
            name="shippingInstructions"
            type="text"
            value={values.shippingInstructions}
            onChange={(e) => {
              handleChange(e);
              props.updateDraftMR("shippingInstructions", e.target.value)
            }}
            isInvalid={!!errors.shippingInstructions}
            rows={4}
            cols={40}
          />
        </Form.Group>
        
      </Form>
    )}
  </Formik>
);

export default NewMaterialRequestForm;
