import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import firebase from "../components/firebase/Firebase";
import ProtectedRoute from "../components/login/ProtectedRoute";
import NewOrderView from "./newOrder/NewOrderView";
import DraftOrder from "./newOrder/DraftOrder"
import DirectOrder from "./newOrder/DirectOrder";
import PurchasingView from "./PurchasingView";
import SignInView from "./SignInView";
import MaterialRequestDetail from "./MaterialRequestDetail";
import PurchaseOrderDetail from "./PurchaseOrderDetail";
import NewPurchaseOrder from "./NewPurchaseOrder";
import OrdersView from "./OrdersView";
import SignIn from "../components/login/SignIn";
import { VersionNumber } from "../VersionNumber";

import "./MainView.css";
const version = VersionNumber;
class MainView extends Component {
  state = {
    loggedIn: false,
    isPurchasing: false,
  };
  async componentDidMount() {
    firebase.auth().onAuthStateChanged((userLoggedIn) => {
      if(userLoggedIn){
        userLoggedIn.getIdTokenResult().then(idTokenResult => {
          if(idTokenResult.claims.userType && idTokenResult.claims.userType === "purchasing"){
              this.setState({isPurchasing: true})
          }
          this.setState({ loggedIn: true });
      })
      }
    });
  }

  render() {
    return (
      <Container fluid>
        <header>
          <Row>
            <Col>
              <img src="AGC-logo.png" alt="Logo" />
            </Col>
            <Col>
              <h1>Order Tracking</h1>
            </Col>
            <Col>
              <SignIn />
            </Col>
          </Row>
        </header>

        <Row className="mainbody">
          <Col>
            <Switch>
              <Route exact path={"/"}>
                <Redirect to="/sign-in" />
              </Route>
              <Route path={"/sign-in"}>
                {this.state.loggedIn ? (
                  <Redirect
                    to={
                      this.state.isPurchasing
                        ? "/purchasing"
                        : "/foreman-orders"
                    }
                  />
                ) : (
                  <SignInView />
                )}
              </Route>
              <ProtectedRoute path="/foreman-orders" component={OrdersView}/>
              <ProtectedRoute path="/purchasing" component={PurchasingView} />
              <ProtectedRoute path="/new-order" component={NewOrderView} />
              <ProtectedRoute path="/new-order-draft" component={DraftOrder} />
              <ProtectedRoute
                path="/new-material-request"
                component={NewOrderView}
              />
              <ProtectedRoute
                path="/new-purchase-order"
                component={NewPurchaseOrder}
              />
              <ProtectedRoute path="/direct-order" component={DirectOrder} />
              <ProtectedRoute
                path="/material-requests"
                component={MaterialRequestDetail}
              />
              <ProtectedRoute
                path="/purchase-orders"
                component={PurchaseOrderDetail}
              />
            </Switch>
            <span className="version-number">Version {version}</span>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MainView;
