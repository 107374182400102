import { SortDirection } from "./SortDirection";

export class SortedBy {
    submittedDate: SortDirection;
    neededDate: SortDirection;
    orderedDate: SortDirection;
    orderer: SortDirection;
    constructor(){
      this.submittedDate = SortDirection.None;
      this.neededDate = SortDirection.None;
      this.orderedDate = SortDirection.None;
      this.orderer = SortDirection.None;
    }
  }