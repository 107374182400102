import Order from "../components/order/Order";


const filter = (orders: Order[], filterField: string[], filterText: string ) => {
    filterText = filterText.toUpperCase();
    let result;
    if(filterField.length > 1 && filterText !== ''){
        result = orders.filter(order => {
            let field = (order as any)[filterField[0]].toUpperCase() + "-" + (order as any)[filterField[1]].toUpperCase()
            return field.substring(0, filterText.length) === filterText.substring(0, filterText.length)
        })
    }
    else {
        result = orders.filter(order => {return (order as any)[filterField[0]].substring(0, filterText.length).toUpperCase() === filterText.substring(0, filterText.length)})
    }
    
    return result;
}

export default filter;