import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Card from 'react-bootstrap/Card'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OrderTimeLine from "../components/orderTimeLine/OrderTimeLine";
import FileUploader from "../components/fileUpload/FileUpload";
import "./OrderDetail.css";
import "../components/customButton/CustomButton.css";
import Database from "../components/database/Database";
import Order from "../components/order/Order";
import OrderIssues from "../components/orderDetailForm/OrderIssues";
import FileDisplay from "../components/fileDisplay/FileDisplay";
import DisplayOrder from "../components/displayOrder/DisplayOrder";
import DisplayOrderItems from "../components/displayOrder/DisplayOrderItems";
class PurchaseOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.db = new Database();
    this.projectNumber = this.props.location.pathname
      .split("/")[2]
      .split("-")[0];

    let materialReqAndPurchaseNumber = this.props.location.pathname
      .split("/")[2]
      .split("-");
    this.materialReq = materialReqAndPurchaseNumber[1];
    if (materialReqAndPurchaseNumber.length > 2) {
      this.purchaseNumber = materialReqAndPurchaseNumber[2];
    } else {
      this.purchaseNumber = 0;
    }
  }
  state = {
    ...new Order(),
    projectNumber: "",
    MRurls: "",
    PRurls: "",
    issueurls: "",
  };

  passURL = (urls, URLType) => {
    this.db.updateAttachmentURL(
      this.projectNumber,
      this.materialReq,
      this.purchaseNumber,
      urls,
      URLType
    );
    if(URLType === "MR"){
      this.setState({ MRurl: urls });
      this.setState({materialRequestURL: urls});
    }
    else if(URLType === "PO"){
      this.setState({ PRurls: urls });
      this.setState({purchaseOrderURL: urls});
    }
    else if(URLType === "ISSUE"){
      this.setState({ issueurls: urls });
      this.setState({issueURL: urls});
    }
  };

  async componentDidMount() {
    this.db.getOrderDetail(
      this.projectNumber,
      this.materialReq,
      this.purchaseNumber,
      (order) => {
        this.setState({ ...order });
        this.setState({ updated: true });
      }
    );
  }

  updateStatus = (status) => {
    this.setState({status: status});
    this.db.updateOrderStatus(
      this.projectNumber,
      this.materialReq,
      this.purchaseNumber,
      status
      )
  };

  cancelOrder = (status) => {
    let message = "Are you sure you want to cancel the order?"
    let value = window.confirm(message)
    if(value){
      this.updateStatus(status);
    }
  }
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="OrderDetail">
        <Button onClick={this.goBack}>Back</Button>
        <Card>
          <Card.Header>Order Timeline</Card.Header>
          <Card.Body>
            <Row>
              <OrderTimeLine
            name="submitted"
            checked={this.state.submittedDate.length > 0 ? true : false}
            label="Material Request Submitted:"
            date={this.state.submittedDate}
            completedClass={
              this.state.submittedDate.length > 0 ? "Completed" : " "
            }
          />
            </Row>
            <Row>
            <OrderTimeLine
            name="orderDate"
            checked={this.state.orderedDate.length > 0 ? true : false}
            label="Purchase Order Date:"
            date={this.state.orderedDate}
            completedClass={
              this.state.orderedDate.length > 0 ? "Completed" : " "
            }
          />
            </Row>
          <Row>
          <OrderTimeLine
            name="receivedShop"
            checked={this.state.receivedShop.length > 0 ? true : false}
            label="Received Shop:"
            date={this.state.receivedShop}
            completedClass={
              this.state.receivedShop.length > 0 ? "Completed" : " "
            }
          />
          </Row>
          <Row>
          <OrderTimeLine
            name="receivedField"
            checked={this.state.receivedField.length > 0 ? true : false}
            label="Received Field:"
            date={this.state.receivedField}
            completedClass={
              this.state.receivedField.length > 0 ? "Completed" : " "
            }
          />
          </Row>
          <Row>
          <OrderTimeLine
            name="completed"
            checked={this.state.completedDate.length > 0 ? true : false}
            label="Completed:"
            date={this.state.completedDate}
            completedClass={
              this.state.completedDate.length > 0 ? "Completed" : " "
            }
          />
          </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>Purchase Order {this.state.projectNumber + "-" + this.state.purchaseNumber}</Card.Header>
          <Card.Body>
            <Row>
                <Col>
                Material Request : 
                </Col>
                <Col>{this.state.projectNumber}-{this.state.materialReq}</Col>
              </Row>
              <Row>
                <Col>
                  Request Status: 
                </Col>
                <Col>{this.state.status}</Col>
              </Row>
              <Row>
                <Col>
                Date Needed: 
                </Col>
                <Col>{new Date(this.state.neededDate).toLocaleDateString('en-US', {timeZone: "UTC"})}</Col>
              </Row>
              <Row>
                <Col>
                Short Description: 
                </Col>
                <Col>{this.state.description}</Col>
              </Row>
              <Row>
                <Col>
                Requested by: 
                </Col>
                <Col>{this.state.orderer}</Col>
              </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Order Details</Card.Header>
          <Card.Body>
            <Row>
                <Col>
                Vendor: 
                </Col>
                <Col>{this.state.vendor}</Col>
              </Row>
            <Row>
                <Col>
                Order Details: 
                </Col>
                <Col>
                  <DisplayOrder orderDetails={this.state.orderDetails}/>
                </Col>
              </Row>
              <Row>
                <Col>
                Order: 
                </Col>
                <Col>
                  <DisplayOrderItems orderDetailsItems={this.state.orderItems}/>
                </Col>
              </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            Attachments
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                Material Requests:
              </Col>
              <Col>
              <FileDisplay 
                  passCallback={this.passURL}
                  urls={this.state.materialRequestURL}
                  urlType="MR"
                  allowDelete={true}/>
              </Col>
            </Row>
            <Row>
              <Col>
                Pruchase Orders:
              </Col>
              <Col>
              <FileDisplay 
                  passCallback={this.passURL}
                  urls={this.state.purchaseOrderURL}
                  urlType="PO"
                  allowDelete={true}/>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Shipping Information</Card.Header>
          <Card.Body>
            <Row>
                <Col>
                Shipping Address:
                </Col>
                <Col>{`${this.state.shippingAddress.street} ${this.state.shippingAddress.city}, ${this.state.shippingAddress.state} ${this.state.shippingAddress.zipCode} ${this.state.shippingAddress.country}`}</Col>
              </Row>
              <Row>
                <Col>
                Shipping Instructions: 
                </Col>
                <Col>{this.state.shippingInstructions}</Col>
              </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Update Order Status</Card.Header>
          <Card.Body>
            <Row>
          <Col>
            <button
              className={(this.state.status==="Ordered")? "selectedButton" : "unselectedButton"}
              onClick={(e) => this.updateStatus("Ordered")}
            >
              Ordered
            </button>
            <button
              className={(this.state.status==="Received Shop")? "selectedButton" : "unselectedButton"}
              onClick={(e) => this.updateStatus("Received Shop")}
            >
              Received Shop
            </button>
            <button
              className={(this.state.status==="Received Field")? "selectedButton" : "unselectedButton"}
              onClick={(e) => this.updateStatus("Received Field")}
            >
              Received Field
            </button>
            <button
              className={(this.state.status==="Complete")? "selectedButton" : "unselectedButton"}
              onClick={(e) => this.updateStatus("Complete")}
            >
              Complete
            </button>
            <Button
              variant="danger"
              active={(this.state.status==="Canceled")? true : false}
              onClick={(e) => this.cancelOrder("Canceled")}
            >
              Cancel Order
            </Button>
          </Col>
        </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Order Update</Card.Header>
          <Card.Body>
          <Row>
              <Col>
                Issue Uploads:
              </Col>
              <Col>
              <FileDisplay 
                  passCallback={this.passURL}
                  urls={this.state.issueURL}
                  urlType="ISSUE"
                  allowDelete={true}/>
              </Col>
            </Row>
          <OrderIssues
            projectNumber={this.projectNumber}
            materialReq={this.materialReq}
            purchaseNumber={this.purchaseNumber}
            updated={this.state.updated}
          />

          <h5>Upload Issue</h5>
          <FileUploader
            storageLocation="issue"
            passCallback={this.passURL}
            urls={this.state.issueURL}
            urlType="ISSUE"
          />
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Update Attachments</Card.Header>
          <Card.Body>
              <h5>Upload Material Request</h5>
              <FileUploader
                storageLocation="documents"
                passCallback={this.passURL}
                urls={this.state.materialRequestURL}
                urlType="MR"
              />
              <h5>Upload Purchase Order</h5>
              <FileUploader
                storageLocation="pr"
                passCallback={this.passURL}
                urls={this.state.purchaseOrderURL}
                urlType="PO"
              />
              
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default PurchaseOrderDetail;
